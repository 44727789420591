import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom } from
"@remix-run/node";

import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import { ExternalScripts } from "remix-utils/external-scripts";

import notFoundIllustration from "~/assets/not-found.png";
import serverErrorIllustration from "~/assets/server-error.png";
import globalStyles from "~/styles/global.css";
import tailwind from "~/styles/tailwind.css";

import i18next from "./i18next.server";
import { ErrorLayout } from "./layouts/error";
import { createCSRFToken, readCSRFToken, setCSRFToken } from "./services/csrf";

export let links: LinksFunction = () => {
  return [
  { rel: "dns-prefetch", href: "https://static.daffy.org" },
  { rel: "preconnect", href: "https://static.daffy.org" },
  { rel: "preload", href: globalStyles, as: "style" },
  { rel: "preload", href: tailwind, as: "style" },
  { rel: "stylesheet", href: globalStyles },
  { rel: "stylesheet", href: tailwind },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/regular.woff",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/medium.woff",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/bold.woff",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/regular.woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/medium.woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/obviously/bold.woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/sohne/regular.ttf",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/sohne/medium.ttf",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "https://static.daffy.org/fonts/sohne/semibold.ttf",
    crossOrigin: "anonymous"
  }];

};

export let meta: MetaFunction = () => {
  return [{ title: "Daffy for Work" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  let locale = await i18next.getLocale(request);

  let headers = new Headers();

  // CSRF Token
  let csrf = await readCSRFToken(request);
  if (!csrf) {
    csrf = createCSRFToken() as string;
    await setCSRFToken(csrf, headers);
  }

  return json({ locale, csrf }, { headers });
}

export let handle = { i18n: "common" };

export type LoaderData = SerializeFrom<typeof loader>;

export default function App() {
  let { locale } = useLoaderData<typeof loader>();
  let { i18n } = useTranslation();
  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.svg" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  let { t } = useTranslation();
  const error = useRouteError();
  useChangeLanguage("en");

  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === "development") console.error(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.svg" />
        <Meta />
        <Links />
      </head>
      <body className="font-sans h-full min-h-screen min-h-screen-ios tabular-nums">
        {isRouteErrorResponse(error) ?
        <ErrorLayout
          illustration={{
            src: notFoundIllustration,
            alt: t("Not found")
          }}
          title={t("Uh oh! We can’t find the page you’re searching for.")}
          description="Don’t worry, we can get you back on track.<br/>Head over to our <home>homepage</home> or explore our <help>Help Center</help>." /> :


        <ErrorLayout
          illustration={{
            src: serverErrorIllustration,
            alt: t("Server error")
          }}
          title={t("Oops! Something went wrong.")}
          description="Don’t worry, we got you.<br /> Head over to our <home>homepage</home> or explore our <help>Help Center</help>." />}


        <ScrollRestoration />
        <LiveReload />
      </body>
    </html>);

}